import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { SEO } from '../components/seo';
import Layout from '../components/layout';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  topicImage,
  cta,
  dots,
  articleContent,
} from '../components/layout.module.css';
import dotImage from '../images/dots-light.png';

// Template for articles, renders featured image and title at the top followed by content and then CTA

function Page({ data, pageContext }) {
  const page = pageContext;
  const meta = {
    title: page.frontmatter.title,
    description: page.frontmatter.description,
  };

  console.log(data.mdx.frontmatter.featImg);
  const image = getImage(data.mdx.frontmatter.featImg);
  return (
    <Layout meta={meta} pageTitle={page.frontmatter.title}>
      <div className={articleContent}>
        <h1>{page.frontmatter.title}</h1>
        <GatsbyImage
          className={topicImage}
          image={image}
          alt={data.mdx.frontmatter.altImg}
        />
        <MDXRenderer>{page.body}</MDXRenderer>
        <Link className={cta} to="/buzhou">
          {data.mdx.frontmatter.cta}
        </Link>
        <img className={dots} src={dotImage} alt="dots" />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        excerpt
        metaDesc
        category
        featImg {
          childImageSharp {
            gatsbyImageData
          }
        }
        altImg
        cta
        outreach
        ogDesc
        ogImage
        twDesc
        twImage
        slug
        template
      }
      body
    }
  }
`;

export default Page;

export const Head = (pageContext) => <SEO pageContext={pageContext} />;
